<router>
{
    name: 'DevOps Dashboard'
}
</router>
<template lang="pug">
v-container(fluid)
</template>
<script>
import dashboard from '@/components/dashboard.vue';
export default {
    meta: {
        role:'devops'
    },
    extends: dashboard,
    data () {
        return {
            cards: [],
        };
    },
    mounted () {
        window.devopsdashbaordvm = this;
    }
};
</script>

<style scoped>
</style>
